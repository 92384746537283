<template>
  <div>
    <loading-overlay :active="loading" :is-full-page="true" loader="bars"/>
    <CCard>
      <CCardHeader class="text-center bg-dark text-white">
        <b>{{$t('label.BlQuery')}}  </b>
      </CCardHeader>
      <div class="mt-3">
        <CRow class="justify-content-center m-0">
          <CCol sm="12" lg="3">
            <div class="form-group form-row" rol="group">
              <label class="col-form-label-sm col-sm-12 col-lg-3 text-right Label">{{$t('label.vessel')}}</label>
              <div class="col-sm-12 col-lg-9">
                <v-select 
                  id="v-select-small"
                  style="font-size: 11px;"
                  :placeholder="$t('label.select')"
                  :options="VesselOptions"
                  :reduce="option => option.value"
                  v-model="VesselId"
                  @input="onChangeVesselId"
                >
                  <template #no-options="{ }">
                    {{$t('label.noResultsFound')}}
                  </template>
                </v-select>
              </div>
            </div>
          </CCol>
          <CCol sm="12" lg="4">
            <div class="form-group form-row" rol="group">
              <label class="col-form-label-sm col-sm-12 col-lg-4 text-right Label">{{$t('label.visitItineraty')}}</label>
              <div class="col-sm-12 col-lg-8">
                <v-select 
                  id="v-select-small"
                  style="font-size: 11px;"
                  :placeholder="$t('label.select')"
                  :options="VisitOrItineraryOptions"
                  :reduce="option => option.value"
                  v-model="VisitIdOrItineraryId"
                  :disabled="!VesselId"
                  @input="onChangeVisitIdOrItineraryId"
                >
                  <template #no-options="{ }">
                    {{$t('label.noResultsFound')}}
                  </template>
                  <template #option="item">
                    <p v-if="item.value" class="m-0">{{ `${item.VesselName} - ${item.Voyage}` }}</p>
                    <p v-else class="m-0">{{ item.label }}</p>
                    <cite v-if="item.value">{{ `${item.LabelArrival ?? ''} ${formatDateTimeWithSlash(item.Arrival)} - ${item.LabelDeparture ?? ''} ${formatDateTimeWithSlash(item.Departure)}` }} </cite>
                  </template>
                </v-select>
              </div>
            </div>
          </CCol>
          <CCol sm="12" lg="3">
            <div class="form-group form-row" rol="group">
              <label class="col-form-label-sm col-sm-12 col-lg-1 text-right Label">{{$t('label.bl')}}</label>
              <div class="col-sm-12 col-lg-11">
                <div v-show="!VesselId" id="BlSearch" class="form-group form-row m-0" style="flex-wrap: nowrap;">
                  <v-select 
                    id="v-select-small"
                    ref="BlSearch"
                    class="form-bl-Search"
                    style="font-size: 11px;"
                    :options="BlOption"
                    :reduce="option => option.value"
                    v-model.trim="BlCargoId"
                    :clearSearchOnSelect="false"
                    :clearable="false"
                    :filterable="false"
                    :dropdownShouldOpen="dropdownShouldOpen"
                    :map-keydown="handlers"
                    @search="(search, loading) => BlSearch = search"
                    @input="onInputBlCargoId"
                  > 
                    <template #no-options="{}">
                      {{$t('label.noResultsFound')}}
                    </template>
                    <template #option="{ Json, label, value }">
                      <div class="w-100 p-1" :class="dataBl.BlCargoId == value ? 'vs__dropdown-option--highlight' : ''">
                        <b>{{ label }}</b><cite>{{ `(${Json.TpBlName})` }}</cite>
                        <br v-if="value"/>
                        <cite v-if="value&&Json?.VesselName">
                          {{ `${Json?.VesselName??''}(${Json.Voyage??''}) - ${Json.LabelArrival ?? ''} ${formatDateTimeWithSlash(Json.Arrival)}` }} 
                        </cite>
                      </div>
                    </template>
                  </v-select>
                  <div class="input-group-append">
                    <div class="d-flex align-items-start">
                      <CButton
                        size="sm"
                        class="btn-light-watch mr-2"
                        style="padding: 0.15rem 0.4rem;"
                        @click="getBlCargoList()"
                      >
                        <CIcon name="cil-search"/>
                      </CButton>

                      <CButton
                        size="sm"
                        class="btn-add"
                        style="padding: 0.15rem 0.4rem;"
                        @click="toggleBlAdd"
                      >
                        <CIcon name="cil-plus"/>
                      </CButton>
                    </div>
                  </div>
                </div>
                <div v-if="VesselId" class="form-group form-row m-0" style="flex-wrap: nowrap;">
                  <v-select 
                    id="v-select-small"
                    :placeholder="$t('label.select')"
                    style="font-size: 11px; width: 100%"
                    :options="BlOption"
                    :reduce="option => option.value"
                    v-model.trim="BlCargoId"
                    :disabled="!VisitIdOrItineraryId"
                    @input="onChangeBlCargoId"
                  >
                    <template #no-options="{ }">
                      {{$t('label.noResultsFound')}}
                    </template>
                    <template #option="{ Json, label, value }">
                      <div v-if="value">
                        <b>{{ label }}</b><cite>{{ `(${Json?.TpBlName})` }}</cite>
                      </div>
                      <div v-else>{{ label }}</div>
                    </template>
                  </v-select>
                  <div class="input-group-append">
                    <div class="d-flex align-items-start">
                      <CButton
                        size="sm"
                        class="btn-add"
                        style="padding: 0.15rem 0.4rem;"
                        @click="toggleBlAdd"
                      >
                        <CIcon name="cil-plus"/>
                      </CButton>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </CCol>
        </CRow>
        <CCol v-show="Object.keys(dataBl).length != 0" sm="12" class="mb-2">
          <CCollapse :show="BlCollapse == 0">
            <BlInfo 
              :Bl="dataBl"
              @Update="UpdateBl"
              @getBl="(item, fgMaster) => { this.getBl(item, fgMaster) }"
            />
          </CCollapse>
          <CCollapse :show="BlCollapse == 1">
            <Container/>
          </CCollapse>
        </CCol>
      </div>
    </CCard>
    <ModalBl
      :modal.sync="ModalBl"
      :items="[]"
      :title="`${$t('label.nuevo')} ${$t('label.bl')}`"
      @Update-list="UpdateBl"
      @close="ModalBl = false"
    />
  </div>
</template>
<script>
import GeneralMixin from '@/_mixins/general';
import CustomTabs from '@/components/tabs/CustomTabs';
import CustomTab from '@/components/tabs/CustomTab';
import BlInfo from './bl-info'
import ModalBl from './taps/basicData/modal-bl/index';
import Container from '@/pages/yard-management/container/container-index';
import { mapState } from 'vuex';
import { DateFormater } from '@/_helpers/funciones';

//data
function data() {
  return {
    ModalBl: false,
    VesselId: '',
    VesselList: [],
    VisitIdOrItineraryId: '',
    VisitOrItineraryList: [],
    BlCargoId: '',
    BlSearch: '',
    BlList: [],
    alertModal: false,
    yardSelected: '',
    dataYard: [],
    setDataYard: [],
  }
}

//methods
function getVesselList() {
  this.$store.state.yardManagement.loading = true;
  this.$http.get("Vessel-list", { Filter: 'ACTIVO' })
  .then(response => {
    this.VesselList = response.data.data ?? [];
  }).catch( err => {
      this.$notify({
      group: 'container',
      title: '¡Error!',
      text: err,
      type: "error"
      });
  })
  .finally(() => {
    this.$store.state.yardManagement.loading = false;
  });
}

function getVisitOrItineraryList() {
  this.$store.state.yardManagement.loading = true;
  this.$http.get("YardBlCargoVisitItinerary-by-CompanyBranchId", { VesselId: this.VesselId, CompanyBranchId: this.branch.CompanyBranchId })
  .then(response => {
    this.VisitOrItineraryList = response.data.data ? response.data.data : [];
  }).catch( err => {
      this.$notify({
      group: 'container',
      title: '¡Error!',
      text: err,
      type: "error"
      });
  })
  .finally(() => {
    this.$store.state.yardManagement.loading = false;
  });
}

async function getBlCargoList(update = false) {
  if (this.VisitIdOrItineraryId || this.BlSearch.length != 0) {
    this.$store.state.yardManagement.loading = true;
    this.BlList = [];
    let Vessel = {};
    if (this.VisitIdOrItineraryId) {
      Vessel = this.VisitOrItineraryOptions.find(e => e.value == this.VisitIdOrItineraryId) ?? {};
    }
    await this.$http.get("BlCargo-list", { BlCargoJson: { BlCargoJson: [{VesselId: this.VesselId ?? '', VisitId: Vessel?.VisitId ?? '', ItineraryId: Vessel?.ItineraryId ?? '', SearchKey: this.BlSearch}] } })
    .then(response => {
      this.BlList = response?.data?.data ?? [];
      if (!this.VisitIdOrItineraryId && !update) {
        this.$refs.BlSearch.open = true;
      }
    }).catch( err => {
        this.$notify({
        group: 'container',
        title: '¡Error!',
        text: err,
        type: "error"
        });
    })
    .finally(() => {
      this.$store.state.yardManagement.loading = false;
    });
  }
}

function onChangeVesselId() {
  this.VisitIdOrItineraryId = '';
  this.VisitOrItineraryList = [];
  this.$refs.BlSearch.search = '';
  this.BlCargoId = '';
  this.BlSearch = '';
  this.BlList = [];
  this.$store.state.yardManagement.dataBl = {};
  this.$store.state.yardManagement.BlTabIndex = 0;
  if (this.VesselId) {
    this.getVisitOrItineraryList();
  }
}

function onChangeVisitIdOrItineraryId() {
  this.$refs.BlSearch.search = '';
  this.BlCargoId = '';
  this.BlSearch = '';
  this.BlList = [];
  this.$store.state.yardManagement.dataBl = {};
  this.$store.state.yardManagement.BlTabIndex = 0
  if (this.VisitIdOrItineraryId) {
    this.getBlCargoList();
  }
}

function onInputBlCargoId(event) {
  this.$store.state.yardManagement.loading = true;
  setTimeout(() => {
    this.$store.state.yardManagement.BlCollapse = 0;
    this.$store.state.yardManagement.FgContainerNavigation = false;
    this.$store.state.yardManagement.dataContainer = {};
    this.$store.state.yardManagement.dataBl = this.BlList?.find(item => item.BlCargoId == event);
    this.$refs.BlSearch.search = this.dataBl?.BlNro ?? '';
    this.BlSearch = this.dataBl?.BlNro ?? '';
    this.BlCargoId = '';
    this.$store.state.yardManagement.BlTabIndex = 0;
    this.$store.state.yardManagement.loading = false;
  }, 10)
}

function onChangeBlCargoId(id) {
  this.BlCargoId = id;
  this.$store.state.yardManagement.dataBl = {};
  this.$store.state.yardManagement.BlTabIndex = 0
  if (id) {
    this.$store.state.yardManagement.dataBl = this.BlList?.find(item => item.BlCargoId == id) ?? {};
  }
}

function toggleBlAdd() {
  this.titleModal = this.$t('label.nuevo')+' '+this.$t('label.bl');
  this.ModalBl = true;
}

async function UpdateBl(item) {
  this.BlCargoId = '';
  this.BlList = [];
  if (this.VesselId && this.VisitIdOrItineraryId) {
    await this.getBlCargoList(true);
    this.BlCargoId = item?.BlCargoId ?? '';
  } else {
    this.VesselId = '';
    this.VisitIdOrItineraryId = '';
    this.BlSearch = item.BlNro;
    this.$refs.BlSearch.search = item.BlNro;
    await this.getBlCargoList(true);
  }
  this.$store.state.yardManagement.dataBl = this.BlList?.find(e => e.BlCargoId == item.BlCargoId) ?? {};
}

async function getBl(item, fgMaster = false) {
  this.$store.state.yardManagement.BlCollapse = 0;
  this.$store.state.yardManagement.FgContainerNavigation = false;
  this.$store.state.yardManagement.dataContainer = {};
  this.VesselId = '';
  this.VisitIdOrItineraryId = '';
  this.BlCargoId = '';
  this.BlList = [];
  this.BlSearch = fgMaster ? item.MasterBlNro : item.BlNro;
  this.$refs.BlSearch.search = this.BlSearch;
  await this.getBlCargoList(true);
  this.$store.state.yardManagement.dataBl = this.BlList?.find(e => e.BlCargoId == (fgMaster ? item.BlCargoMasterId : item.BlCargoId)) ?? {};
  this.$store.state.yardManagement.BlTabIndex = 0;
} 

function dropdownShouldOpen(VueSelect) {
  return VueSelect.open;
}

function formatDateTimeWithSlash(date) {
  return date ? DateFormater.formatDateTimeWithSlash(date) : 'N/A';
}

function handlers(map, vm) {
  return {
    ...map,
    13: (e) => {

    }
  }
}

//Computed
function VesselOptions(){
  let chart = [{
    value: '', 
    label: this.$t('label.select'),
  }]
  this.VesselList.map(function(e){
    chart.push({
      ...e,
      value: e.VesselId, 
      label: e.VesselName,
    })
  })
  return chart;
}

function VisitOrItineraryOptions(){
  let chart = [{
    value: '', 
    label: this.$t('label.select'),
  }]
  this.VisitOrItineraryList.map(function(e){
    chart.push({
      ...e,
      value: e.VisitId ?? e.ItineraryId, 
      label: `${e.Voyage} - ${e.LabelArrival ?? ''} ${formatDateTimeWithSlash(e.Arrival)}`,
    })
  })
  return chart;
}

function BlOption() {
  let chart = [];
  if (this.VisitIdOrItineraryId) {
    chart.push({
      value: '', 
      label: this.$t('label.select'),
    });
  }
  this.BlList.map(e => {
    chart.push({
      value: e.BlCargoId,
      label: e.BlNro,
      Json: {...e }
    });
  });
  return chart;
}

export default {
  name: 'index',
  components: {
    CustomTabs,
    CustomTab,
    BlInfo,
    ModalBl,
    Container,
   },
  data,
  mounted(){
    this.$store.state.yardManagement.yardCollapse == 0;
    window.addEventListener('click', (e) => {
      if (!this.VisitIdOrItineraryId && document.getElementById('BlSearch') && !document.getElementById('BlSearch').contains(e.target)) {
        if (this.$refs?.BlSearch?.open) {
          this.$refs.BlSearch.open = false;
        }
      }
    })
    document.getElementById('BlSearch').addEventListener('keydown', (e) => {
      if (e.key === 'Enter' && !this.VisitIdOrItineraryId) {
        this.getBlCargoList();
      }
    })
    this.getVesselList()
  },
  beforeDestroy () {
    this.$store.state.yardManagement.FgBlNavigation = false;
    this.$store.state.yardManagement.yardCollapse = 0;
    this.$store.state.yardManagement.dataContainer = {};
    this.$store.state.containerSearch.dataContainer = {};
    this.$store.state.yardManagement.loading= false;
    this.$store.state.yardManagement.yardData = {};
    this.$store.state.yardManagement.dataBl = {};
  },
  mixins: [GeneralMixin],
  methods: {
    getVesselList,
    getVisitOrItineraryList,
    getBlCargoList,
    toggleBlAdd,
    UpdateBl,
    getBl,
    dropdownShouldOpen,
    formatDateTimeWithSlash,
    handlers,
    onChangeVesselId,
    onChangeVisitIdOrItineraryId,
    onInputBlCargoId,
    onChangeBlCargoId,
  },
  computed: {
    VesselOptions,
    VisitOrItineraryOptions,
    BlOption,
    ...mapState({
      branch: state => state.auth.branch,
      config: state => state.auth.config,
      loading: state => state.yardManagement.loading,
      dataBl: state => state.yardManagement.dataBl,
      BlCollapse: state => state.yardManagement.BlCollapse,
    })
  },
  watch: {
    
  },
};
</script>
<style lang="scss">
@import '@/pages/yard-management/css/style';
</style>
<style scoped>
.Containers-header{
  background: #4e5a70;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
}
.Containers-Search{
  background: #ffffff;
  border-radius: 10px;
}
</style>